.Img.img.img-square img,
.Img.img.img-1by1 img,
.Img.img.img-5by4 img,
.Img.img.img-4by3 img,
.Img.img.img-3by2 img,
.Img.img.img-5by3 img,
.Img.img.img-16by9 img,
.Img.img.img-2by1 img,
.Img.img.img-3by1 img,
.Img.img.img-4by5 img,
.Img.img.img-3by4 img,
.Img.img.img-2by3 img,
.Img.img.img-3by5 img,
.Img.img.img-9by16 img,
.Img.img.img-1by2 img,
.Img.img.img-1by3 img,
.Img.img.img-square .img-ratio,
.Img.img.img-1by1 .img-ratio,
.Img.img.img-5by4 .img-ratio,
.Img.img.img-4by3 .img-ratio,
.Img.img.img-3by2 .img-ratio,
.Img.img.img-5by3 .img-ratio,
.Img.img.img-16by9 .img-ratio,
.Img.img.img-2by1 .img-ratio,
.Img.img.img-3by1 .img-ratio,
.Img.img.img-4by5 .img-ratio,
.Img.img.img-3by4 .img-ratio,
.Img.img.img-2by3 .img-ratio,
.Img.img.img-3by5 .img-ratio,
.Img.img.img-9by16 .img-ratio,
.Img.img.img-1by2 .img-ratio,
.Img.img.img-1by3 .img-ratio {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.Img-grayscale img {
  filter: grayscale(1);
}
.Img.img.img-square img,
.Img.img.img-1by1 img,
.Img.img.img-5by4 img,
.Img.img.img-4by3 img,
.Img.img.img-3by2 img,
.Img.img.img-5by3 img,
.Img.img.img-16by9 img,
.Img.img.img-2by1 img,
.Img.img.img-3by1 img,
.Img.img.img-4by5 img,
.Img.img.img-3by4 img,
.Img.img.img-2by3 img,
.Img.img.img-3by5 img,
.Img.img.img-9by16 img,
.Img.img.img-1by2 img,
.Img.img.img-1by3 img,
.Img.img.img-square .img-ratio,
.Img.img.img-1by1 .img-ratio,
.Img.img.img-5by4 .img-ratio,
.Img.img.img-4by3 .img-ratio,
.Img.img.img-3by2 .img-ratio,
.Img.img.img-5by3 .img-ratio,
.Img.img.img-16by9 .img-ratio,
.Img.img.img-2by1 .img-ratio,
.Img.img.img-3by1 .img-ratio,
.Img.img.img-4by5 .img-ratio,
.Img.img.img-3by4 .img-ratio,
.Img.img.img-2by3 .img-ratio,
.Img.img.img-3by5 .img-ratio,
.Img.img.img-9by16 .img-ratio,
.Img.img.img-1by2 .img-ratio,
.Img.img.img-1by3 .img-ratio {
  height: 100%;
  width: 100%;
}
.Img.img.img-square,
.Img.img.img-1by1 {
  padding-top: 100%;
}
.Img.img.img-5by4 {
  padding-top: 80%;
}
.Img.img.img-4by3 {
  padding-top: 75%;
}
.Img.img.img-3by2 {
  padding-top: 66.6666%;
}
.Img.img.img-5by3 {
  padding-top: 60%;
}
.Img.img.img-16by9 {
  padding-top: 56.25%;
}
.Img.img.img-2by1 {
  padding-top: 50%;
}
.Img.img.img-3by1 {
  padding-top: 33.3333%;
}
.Img.img.img-4by5 {
  padding-top: 125%;
}
.Img.img.img-3by4 {
  padding-top: 133.3333%;
}
.Img.img.img-2by3 {
  padding-top: 150%;
}
.Img.img.img-3by5 {
  padding-top: 166.6666%;
}
.Img.img.img-9by16 {
  padding-top: 177.7777%;
}
.Img.img.img-1by2 {
  padding-top: 200%;
}
.Img.img.img-1by3 {
  padding-top: 300%;
}
.Img.img.img-16x16 {
  height: 16px;
  width: 16px;
}
.Img.img.img-16x16 img {
  height: 100%;
}
.Img.img.img-24x24 {
  height: 24px;
  width: 24px;
}
.Img.img.img-24x24 img {
  height: 100%;
}
.Img.img.img-32x32 {
  height: 32px;
  width: 32px;
}
.Img.img.img-32x32 img {
  height: 100%;
}
.Img.img.img-48x48 {
  height: 48px;
  width: 48px;
}
.Img.img.img-48x48 img {
  height: 100%;
}
.Img.img.img-64x64 {
  height: 64px;
  width: 64px;
}
.Img.img.img-64x64 img {
  height: 100%;
}
.Img.img.img-96x96 {
  height: 96px;
  width: 96px;
}
.Img.img.img-96x96 img {
  height: 100%;
}
.Img.img.img-128x128 {
  height: 128px;
  width: 128px;
}
.Img.img.img-128x128 img {
  height: 100%;
}
