.PlacesInput .react-geocoder-results {
  position: fixed;
  top: calc(var(--top) + 0px);
  left: calc(var(--left) + 8px);
  width: calc(var(--width) - 16px);
  background: white;
  z-index: var(--z-index);
  border: 1px solid #efefef;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  max-height: 350px;
  overflow-y: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.PlacesInput .react-geocoder-results-abs {
  position: absolute;
  top: 100%;
  left: 8px;
  width: calc(100% - 16px);
  background: white;
  z-index: var(--z-index);
  border: 1px solid #efefef;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  max-height: 350px;
  overflow-y: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.PlacesInput .react-geocoder-item {
  padding: 6px 15px;
  min-height: 40px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.PlacesInput .react-geocoder-item:not(:last-child) {
  border-bottom: 1px solid #efefef;
}