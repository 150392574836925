@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~@fontsource/poppins/400.css';
@import '~@fontsource/poppins/500.css';
@import '~@fontsource/poppins/600.css';
@import '~@fontsource/roboto/500.css';

:root {
  --color-primary-1: rgba(139, 164, 249, 1);
  --color-primary-2: rgba(188, 156, 255, 1);

  --color-secondary-1: #50a1ff;
  --color-secondary-2: #50bfff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply appearance-none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

@layer utilities {
  .first-letter\:uppercase::first-letter {
    text-transform: uppercase;
  }
}

.bg-primary-to-t {
  @apply bg-gradient-to-t;
  @apply from-primary-1;
  @apply to-primary-2;
}

.bg-primary-to-b {
  @apply bg-gradient-to-b;
  @apply from-primary-1;
  @apply to-primary-2;
}

.bg-primary-to-r {
  @apply bg-gradient-to-r;
  @apply from-primary-1;
  @apply to-primary-2;
}

.bg-primary-to-l {
  @apply bg-gradient-to-l;
  @apply from-primary-1;
  @apply to-primary-2;
}

.bg-secondary-to-t {
  @apply bg-gradient-to-t;
  @apply from-secondary-1;
  @apply to-secondary-2;
}

.bg-secondary-to-b {
  @apply bg-gradient-to-b;
  @apply from-secondary-1;
  @apply to-secondary-2;
}

.bg-secondary-to-r {
  @apply bg-gradient-to-r;
  @apply from-secondary-1;
  @apply to-secondary-2;
}

.bg-secondary-to-l {
  @apply bg-gradient-to-l;
  @apply from-secondary-1;
  @apply to-secondary-2;
}

/* stripe card styles */
.StripeElement--empty {
  @apply border;
  @apply rounded;
  @apply px-4;
  @apply py-3;
  @apply text-lg;
}

.StripeElement--focus {
  @apply border;
  @apply rounded;
  @apply px-4;
  @apply py-3;
  @apply text-lg;
}

.StripeElement--invalid {
  @apply border;
  @apply border-red-500;
  @apply text-red-500;
  @apply rounded;
  @apply px-4;
  @apply py-3;
  @apply text-lg;
}

.StripeElement--complete {
  @apply border;
  @apply rounded;
  @apply px-4;
  @apply py-3;
  @apply text-lg;
}

/* stream chat */
#root .str-chat,
body .str-chat {
  @apply absolute top-0 left-0 w-full h-full;
}

#root .str-chat-channel,
body .str-chat-channel {
  max-height: 100%;
}

#root .str-chat-channel.messaging .str-chat__main-panel,
body .str-chat-channel.messaging .str-chat__main-panel {
  @apply p-0 border-t;
}

#root .str-chat-channel-list,
body .str-chat-channel-list {
  @apply relative w-full h-auto bg-white left-[unset] min-h-[unset] z-[unset];
  overflow-y: unset;
  box-shadow: unset;
}

#root .str-chat__loading-channels,
body .str-chat__loading-channels {
  @apply w-full;
}

#root .str-chat__message--me .str-chat__message-text-inner,
body .str-chat__message--me .str-chat__message-text-inner {
  @apply text-white bg-black;
}

#root .str-chat__input-flat,
body .str-chat__input-flat {
  @apply fixed bottom-[10px] left-0 w-full pb-6 border-t z-20;
}

#root .str-chat__ul,
body .str-chat__ul {
  @apply pb-20;
}

#root .str-chat__input-flat .rfu-file-upload-button {
  top: 50%;
  transform: translateY(-50%);
}
