.RangeSlider {
  --value: 0;
  --tw-gradient-from: #fa255e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 164, 249, 0));
  --tw-gradient-to: #fa255e;
}

.RangeSlider input[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  margin: 17.5px 0;
  width: 100%;
}

.RangeSlider input[type='range']::-moz-focus-outer {
  border: 0;
}

.RangeSlider input[type='range']:focus {
  outline: 0;
}

.RangeSlider input[type='range']:focus::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
}

.RangeSlider input[type='range']:focus::-ms-fill-lower {
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
}

.RangeSlider input[type='range']:focus::-ms-fill-upper {
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
}

.RangeSlider input[type='range']::-webkit-slider-runnable-track {
  cursor: default;
  height: 14px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), 0 0 2px rgba(13, 13, 13, 0.1);
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
  border: 1px solid white;
  border-radius: 4px;
}

.RangeSlider input[type='range']::-webkit-slider-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: linear-gradient(90deg, var(--tw-gradient-stops));
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 35px;
  width: 35px;
  -webkit-appearance: none;
  margin-top: -11.5px;
}

.RangeSlider input[type='range']::-moz-range-track {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), 0 0 2px rgba(13, 13, 13, 0.1);
  cursor: default;
  height: 14px;
  transition: all 0.2s ease;
  width: 100%;
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
  border: 1px solid white;
  border-radius: 4px;
  height: 7px;
}

.RangeSlider input[type='range']::-moz-range-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: linear-gradient(90deg, var(--tw-gradient-stops));
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 35px;
  width: 35px;
}

.RangeSlider input[type='range']::-ms-track {
  cursor: default;
  height: 14px;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 17.5px 0;
  color: transparent;
}

.RangeSlider input[type='range']::-ms-fill-lower {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), 0 0 2px rgba(13, 13, 13, 0.1);
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
  border: 1px solid white;
  border-radius: 8px;
}

.RangeSlider input[type='range']::-ms-fill-upper {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), 0 0 2px rgba(13, 13, 13, 0.1);
  background: linear-gradient(
    to right,
    #fa255e,
    #fa255e var(--value),
    transparent var(--value)
  );
  border: 1px solid white;
  border-radius: 8px;
}

.RangeSlider input[type='range']::-ms-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: linear-gradient(90deg, var(--tw-gradient-stops));
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 35px;
  width: 35px;
  margin-top: 3.5px;
}

.RangeSlider input[type='range']:disabled::-webkit-slider-thumb,
.RangeSlider input[type='range']:disabled::-moz-range-thumb,
.RangeSlider input[type='range']:disabled::-ms-thumb,
.RangeSlider input[type='range']:disabled::-webkit-slider-runnable-track,
.RangeSlider input[type='range']:disabled::-ms-fill-lower,
.RangeSlider input[type='range']:disabled::-ms-fill-upper {
  cursor: not-allowed;
}
