@import '../index.css';

:root {
  --color-primary-1: #8ba4f9;
  --color-primary-2: #bc9cff;
  --font-primary: 'Quicksand', 'Helvetica Neue', Arial, 'Noto Sans',
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
body {
  font-family: var(--font-primary);
}
.font-primary {
  font-family: var(--font-primary);
}
.font-poppins {
  font-family: 'Poppins', sans-serif;
}
.font-roboto {
  font-family: 'Roboto', sans-serif;
}
.fill-current {
  fill: currentColor;
}
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}
.PhoneInput {
  display: flex;
  align-items: center;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
}
.PhoneInputCountryIcon {
  width: 1.5em;
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: 1em;
  height: var(--PhoneInputCountryFlag-height);
}
.PhoneInputCountryIcon--square {
  width: 1em;
  width: var(--PhoneInputCountryFlag-height);
}
.PhoneInputCountryIcon--border {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}
.PhoneInputInternationalIconPhone {
  opacity: 0.8;
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}
.PhoneInputInternationalIconGlobe {
  opacity: 0.65;
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountrySelect[disabled] {
  cursor: default;
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: 0.3em;
  height: 0.3em;
  margin-left: 0.35em;
  transform: rotate(45deg);
  border: 1px solid currentColor;
  border-top: 0;
  border-left: 0;
}
.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}
.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.PhoneInput {
  align-items: flex-start;
}
.PhoneInput .PhoneInputCountry {
  align-self: flex-start;
  height: 2.8rem;
}
.PhoneInputInput {
  margin-bottom: 0;
}
