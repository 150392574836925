.Switch input + .Switch-toggle {
  padding: 2px;
  transition: background-color 0.3s ease-in-out;
}

.Switch input + .Switch-toggle > div {
  transition: transform 0.3s ease-in-out;
}

.Switch input:checked + .Switch-toggle.primary-1 {
  @apply bg-gradient-to-t from-primary-1 to-primary-2;
}

.Switch input:checked + .Switch-toggle.hot-pink {
  @apply bg-primary;
}

.Switch input:checked + .Switch-toggle > div {
  transform: translateX(100%);
}
