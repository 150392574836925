.StarRating {
  --fill: 100%;
  --fill-color: #8ba4f9;
  background: linear-gradient(to right, var(--fill-color) var(--fill), currentColor var(--fill));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.StarRating-star:before {
  content: '★';
}

.StarRating-star-outlined:before {
  content: '☆';
}