.Checkbox input[type='checkbox'] + span * {
  visibility: hidden;
}

.Checkbox input[type='checkbox']:checked + span {
  @apply bg-primary border-primary;
}

.Checkbox input[type='checkbox']:checked + span * {
  visibility: visible;
}
